import { jsx as _jsx } from "react/jsx-runtime";
import { tableCellClasses as muiTableCellClasses } from '@mui/material';
import { TableCellStyled } from './TableCell.styled';
import { forwardRef } from 'react';
/**
 * **Global Parts** `TableCell` component.
 */
const TableCell = forwardRef((props, ref) => {
    return _jsx(TableCellStyled, { ref: ref, ...props });
});
export { TableCell };
export const tableCellClasses = muiTableCellClasses;
