import { jsx as _jsx } from "react/jsx-runtime";
import { AlertStyled } from './Alert.styled';
import { SvgIcon } from '../SvgIcon';
import circleCheckIcon from 'core/svg/fa/circle-check.svg';
import circleInfoIcon from 'core/svg/fa/circle-info.svg';
import circleXmarkIcon from 'core/svg/fa/circle-xmark.svg';
import { forwardRef } from 'react';
/**
 * **Global Parts** `Alert` component.
 */
const Alert = forwardRef(({ size, icon, severity = 'success', ...muiAlertProps }, ref) => {
    const svgIcon = severity === 'success' ? circleCheckIcon : severity === 'info' ? circleInfoIcon : severity === 'warning' ? circleInfoIcon : circleXmarkIcon;
    const defaultSvgIcon = _jsx(SvgIcon, { icon: svgIcon });
    return _jsx(AlertStyled, { ref: ref, ...muiAlertProps, size: size ?? 'medium', severity: severity, icon: icon ?? defaultSvgIcon });
});
export { Alert };
