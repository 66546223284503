import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SpinnerSvgIcon, TableRow } from 'core/ui';
import { LoadingTableCell } from './TableBodyEmpty.styled';
import { Row } from 'core/styled';
import { Typography } from '@mui/material';
import { getTableBodyEmptyErrorMessage } from './getTableBodyEmptyErrorMessage';
import { t } from 'shared/translations';
const TableBodyEmpty = ({ isLoading, isError, error, columnCount, isEmpty, isSearch, height, width, fitCell }) => {
    const emptySet = (!isLoading || !isError) && isEmpty;
    if (!emptySet)
        return _jsx(_Fragment, {});
    const key = isLoading ? 'loading' : isError ? 'error' : 'emptySet';
    const message = isLoading
        ? t('loading.ellipsis.label')
        : isError
            ? getTableBodyEmptyErrorMessage(error)
            : isSearch
                ? t('nothing.found.matching.your.request')
                : t('there.are.no.items');
    return (_jsx(TableRow, { children: _jsx(LoadingTableCell, { colSpan: columnCount, component: "td", height: height, width: width, fitCell: fitCell, children: _jsxs(Row, { alignItems: "center", gap: ".75rem", justifyContent: "center", children: [isLoading && _jsx(SpinnerSvgIcon, {}), _jsx(Typography, { variant: "body2", children: message })] }) }) }, key));
};
export { TableBodyEmpty };
