import { alpha, tableCellClasses, tableRowClasses, typographyClasses } from '@mui/material';
// TODO: to delete redundant comments later
export const MuiTableContainer = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            color: theme.palette.text.muted,
        }),
    },
};
export const MuiTable = {
    variants: [
        {
            props: { size: 'large' },
            style: ({ theme }) => ({
            // See `sizeLarge` below.
            // padding: theme.spacing(4),
            }),
        },
    ],
    styleOverrides: {
        root: {
            letterSpacing: '-.025em',
        },
    },
};
export const MuiTableHead = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            [`.${tableCellClasses.head}`]: {
                backgroundColor: 'white',
                borderTop: 0,
                [`.${typographyClasses.root}`]: {
                    // TODO: to delete later
                    // color: `${theme.palette.text.muted} !important`,
                    color: theme.palette.text.muted,
                },
            },
        }),
    },
};
export const MuiTableRow = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            // Return if Mui default 'border-bottom' lined behavior is gonna be used.
            //'&:last-child td, &:last-child th': { border: 0 },
            [`&.${tableRowClasses.hover}:hover`]: {
                backgroundColor: alpha(theme.palette.text.primary, 0.015), // .025 tailwind Catalyst
            },
        }),
        // not working but it should (working variant now with '&.MuiTableRow-hover:hover')
        // hover: ({ ownerState, theme }) => ({
        // 	'&:hover': {
        // 		backgroundColor: 'red',
        // 	},
        // }),
        head: ({ ownerState, theme }) => ({
            backgroundColor: theme.palette.background.light,
        }),
    },
};
export const MuiTableCell = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            borderBottomWidth: 0,
            borderTopWidth: '1px',
            borderTopStyle: 'solid',
            borderTopColor: theme.palette.dividerTranslucent,
            backgroundColor: 'inherit',
            color: 'inherit',
            // TODO: make default color `text.muted`, and fix GP <TableCell> from Core, also.
            // color: theme.palette.text.muted,
            // '.MuiTableBody-root > .MuiTableRow-root:first-of-type > &, .MuiTableHead-root > .MuiTableRow-root:first-of-type > &': {
            // '.MuiTableRow-root:first-of-type > &': {
            [`.${tableRowClasses.root}:first-of-type > &`]: {
                borderTopColor: theme.palette.divider,
            },
            [`&.${tableCellClasses.head}`]: {
                // padding: theme.spacing(2, 4), // this is not working
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(2),
            },
        }),
        sizeLarge: ({ ownerState, theme }) => ({
            fontSize: theme.typography.body1.fontSize,
            padding: theme.spacing(4),
        }),
        sizeMedium: ({ ownerState, theme }) => ({
            fontSize: theme.typography.body1.fontSize,
            // padding: '6px 16px', // old spacing TODO: to delete later
            padding: theme.spacing(2, 4),
        }),
        sizeSmall: ({ ownerState, theme }) => ({
            fontSize: theme.typography.caption.fontSize,
            padding: '.25rem 0.875rem', // 4px 14px
        }),
        sizeExtraSmall: ({ ownerState, theme }) => ({
            fontSize: theme.typography.helper.fontSize,
            // padding: '3px 7px',
            padding: theme.spacing(2, 1.5), // (4px, 6px)
            // [`&.${tableCellClasses.head}`]: {
            // 	padding: '.51rem 7px',
            // },
        }),
    },
};
