import { addDays, addMonths, addWeeks, endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek, subDays, subMonths, subYears, } from 'date-fns';
export function getCurrentDayValues() {
    const now = new Date();
    return {
        from: startOfDay(now),
        to: endOfDay(now),
    };
}
/** Last week (7 days) values */
export function getLast7daysValues() {
    const now = new Date();
    return {
        // ? subWeeks(now, 1) // 8 days
        from: startOfDay(subDays(now, 6)),
        to: endOfDay(now),
    };
}
export function getPreviousWeekValues() {
    const now = new Date();
    const previousWeek = subDays(now, 7);
    return {
        // TODO: (later, when localization will be required) use without params
        // from: startOfWeek(previousWeek),
        // to: endOfWeek(previousWeek),
        from: startOfWeek(previousWeek, { weekStartsOn: 1 }),
        to: endOfWeek(previousWeek, { weekStartsOn: 1 }),
    };
}
export function getCurrentWeekValues() {
    const now = new Date();
    return {
        // TODO: (later, when localization will be required) use without params
        // from: startOfWeek(now),
        // to: endOfWeek(now),
        from: startOfWeek(now, { weekStartsOn: 1 }),
        to: endOfWeek(now, { weekStartsOn: 1 }),
    };
}
export function getNextWeekValues() {
    const now = new Date();
    const nextWeek = addWeeks(now, 1);
    return {
        // TODO: (later, when localization will be required) use without params
        // from: startOfWeek(previousWeek),
        // to: endOfWeek(previousWeek),
        from: startOfWeek(nextWeek, { weekStartsOn: 1 }),
        to: endOfWeek(nextWeek, { weekStartsOn: 1 }),
    };
}
export function getLastTwoWeeksValues() {
    const now = new Date();
    return {
        from: startOfDay(subDays(now, 13)),
        to: endOfDay(now),
    };
}
export function getPreviousMonthValues() {
    const now = new Date();
    const previousMonthDate = subMonths(now, 1);
    return {
        from: startOfMonth(previousMonthDate),
        to: endOfMonth(previousMonthDate),
    };
}
/**
 * Gets date range from 4th to 3rd (31 days for 31-days month)
 *
 * @returns
 */
export function getLastMonthValues() {
    const now = new Date();
    const dayFrom = addDays(subMonths(now, 1), 1); //
    return {
        from: startOfDay(dayFrom),
        to: endOfDay(now),
    };
}
export function getCurrentMonthValues() {
    const now = new Date();
    return {
        from: startOfMonth(now),
        to: endOfMonth(now),
    };
}
// TODO: Check if this shortcut is used.
export function getNextMonthValues() {
    const now = new Date();
    const nextMonthDate = addMonths(now, 1);
    return {
        from: startOfMonth(nextMonthDate),
        to: endOfMonth(nextMonthDate),
    };
}
export function getLastYearValues() {
    const now = new Date();
    return {
        from: startOfDay(addDays(subYears(now, 1), 1)),
        to: endOfDay(now),
    };
}
