import { jsx as _jsx } from "react/jsx-runtime";
import { TableCell } from 'core/ui';
import { TableCellRowSpanStyled } from './TableCellRowSpan.styled';
import { forwardRef } from 'react';
/**
 * Creates rowspanned cell and places a text to the top of the cell.
 *
 * @param {boolean} isLast
 * @public
 */
const TableCellRowSpan = forwardRef(({ rowSpan, isLast, children, ...props }, ref) => {
    if (rowSpan === 1)
        return (_jsx(TableCell, { ref: ref, ...props, children: children }));
    return (_jsx(TableCellRowSpanStyled, { ref: ref, ...props, rowSpan: rowSpan, isLast: isLast, children: children }));
});
export { TableCellRowSpan };
