import { IconButton, styled } from '@mui/material';
import { Table, TableCell } from '../../Table';
export const StyledTable = styled(Table)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));
export const DescriptionTableCell = styled(TableCell)(({ theme }) => ({
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.medium,
}));
export const StyledIconButton = styled(IconButton)(({ theme }) => ({
    padding: '0',
    marginBottom: theme.spacing(6),
    ':hover': {
        color: theme.palette.primary.dark,
    },
}));
