import { TableCell } from 'core/ui';
import { shouldNotForwardProps } from 'shared/helpers';
import { styled } from '@mui/material';
export const LoadingTableCell = styled(TableCell, shouldNotForwardProps('width', 'height', 'fitCell'))(({ theme, width, height, fitCell }) => ({
    textAlign: 'center',
    ...(!fitCell && {
        padding: '100px',
        height,
    }),
    ...(width && {
        width,
    }),
}));
