import { jsx as _jsx } from "react/jsx-runtime";
import { TableRowStyled } from './TableRow.styled';
import { forwardRef } from 'react';
/**
 * **Global Parts** `TableRow` component.
 */
const TableRow = forwardRef((props, ref) => {
    return _jsx(TableRowStyled, { ref: ref, ...props });
});
export { TableRow };
