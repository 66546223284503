import { TableRow as MuiTableRow, styled, tableCellClasses } from '@mui/material';
import { shouldNotForwardProps } from 'shared/helpers';
export const TableRowStyled = styled(MuiTableRow, shouldNotForwardProps('isGroupHeader', 'hidden'))(({ theme, isGroupHeader, hidden }) => ({
    ...(isGroupHeader && {
        [`.${tableCellClasses.root}`]: {
            backgroundColor: theme.palette.background.light,
        },
    }),
    ...(hidden && {
        display: 'none',
    }),
}));
