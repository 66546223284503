import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Chip, Popover, Tooltip, Typography } from '@mui/material';
import { DescriptionTableCell, StyledIconButton, StyledTable } from './HotkeysHelperButton.styled';
import { SvgIcon, TableBody, TableCell, TableHead, TableRow } from 'core/ui';
import circleQuestionLightIcon from 'core/svg/fa/light/circle-question.svg';
import { hotkeysDescription } from './hotkeysDescription';
import { t } from 'shared/translations';
import { useState } from 'react';
/**
 * {@link DayPicker} hotkeys helper
 *
 * https://daypicker.dev/guides/accessibility#keyboard-navigation
 *
 * @returns
 */
const HotkeysHelperButton = () => {
    const [anchorElement, setAnchorElement] = useState(null);
    const handleClick = (event) => {
        setAnchorElement(event.currentTarget);
    };
    const handleClickAway = () => {
        setAnchorElement(null);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, { title: t('hotkeys.label'), placement: "right", children: _jsx(StyledIconButton, { color: "primary", disableRipple: true, onClick: handleClick, children: _jsx(SvgIcon, { icon: circleQuestionLightIcon, size: "3xl" }) }) }), _jsx(Popover, { open: Boolean(anchorElement), anchorEl: anchorElement, onClose: handleClickAway, anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                }, transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }, children: _jsxs(StyledTable, { sideCellSpacing: 4, children: [_jsx(TableHead, { children: _jsx(TableRow, { children: _jsx(TableCell, { colSpan: 2, align: "center", children: _jsx(Typography, { fontWeight: "inherit", children: t('hotkeys.label') }) }) }) }), _jsx(TableBody, { children: hotkeysDescription.map((item) => (_jsxs(TableRow, { children: [_jsx(TableCell, { children: _jsx(Chip, { variant: "outlined", size: "small", color: "primary", label: item.hotkey }) }), _jsx(DescriptionTableCell, { children: item.description })] }, item.hotkey))) })] }) })] }));
};
export { HotkeysHelperButton };
