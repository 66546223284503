import { baseApiQuery } from 'shared/helpers/api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { showApiErrorSnackbar } from 'shared/helpers/thunk';
import { supplierActions } from 'admin/modules/suppliers/business';
import { t } from 'shared/translations';
const api = createApi({
    reducerPath: 'api/blacklist',
    tagTypes: ['Blacklist'],
    baseQuery: baseApiQuery,
    endpoints: (builder) => ({
        getBlacklist: builder.query({
            // Use queryFn to make getQueryResult data to become undefined on dialog close.
            queryFn: async ({ supplierPriceListId }, { dispatch }, extraOptions, baseQuery) => {
                if (!supplierPriceListId)
                    return { data: undefined };
                const result = (await baseQuery({ method: 'GET', url: `BlacklistSettings/${supplierPriceListId}` }));
                if (result.error) {
                    dispatch(showApiErrorSnackbar({ header: t('get.blacklist.request.failed'), error: result.error }));
                }
                else {
                    const status = result.meta?.response.status || 200;
                    dispatch(supplierActions.setGetBlacklistQueryResponseStatus(status));
                }
                return result;
            },
            providesTags: (result, error, { supplierPriceListId }) => [{ type: 'Blacklist', supplierPriceListId }],
        }),
        // There are two endpoints for some reason: POST and PUT. If no blacklist fount, then POST must be used, otherwise PUT.
        // updateBlacklist: builder.mutation<void, { status: number; blacklist: BlacklistUpdate }>({
        updateBlacklist: builder.mutation({
            queryFn: async (blacklistUpdate, { dispatch, getState }, extraOptions, baseQuery) => {
                const status = getState().suppliers.blacklistEditDialog.getRequestStatus;
                const result = (await baseQuery({
                    method: status === 204 ? 'POST' : 'PUT',
                    url: `BlacklistSettings`,
                    body: blacklistUpdate,
                }));
                return result;
            },
            // Unnecessary since `refetchOnMountOrArgsChange: true`
            // invalidatesTags: (result, error, { pricelistId }) => [{ type: 'Blacklist', pricelistId }],
        }),
        deleteBlacklist: builder.mutation({
            query: ({ supplierPriceListId }) => ({
                method: 'DELETE',
                url: `BlacklistSettings/${supplierPriceListId}`,
            }),
            invalidatesTags: (result, error, { supplierPriceListId }) => [{ type: 'Blacklist', supplierPriceListId }],
        }),
    }),
});
export const { useGetBlacklistQuery, useUpdateBlacklistMutation, useDeleteBlacklistMutation } = api;
export { api as blacklistApi };
