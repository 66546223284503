import { jsx as _jsx } from "react/jsx-runtime";
import { Autocomplete, Chip, TextField as MuiTextField } from '@mui/material';
import { forwardRef, useRef, useState } from 'react';
import { ErrorTooltip } from 'core/styled';
import { russianDomainEmailRegex } from 'core/utils/validation';
import { t } from 'shared/translations';
const MultiEmailField = forwardRef(({ inputRef, ...props }, ref) => {
    const [tooltipTitle, setTooltipTitle] = useState(null);
    // Used for validation on Enter press.
    const stateCopy = useRef([]);
    return (_jsx(ErrorTooltip, { arrow: true, placement: "bottom-start", open: Boolean(tooltipTitle), title: tooltipTitle, children: _jsx(Autocomplete, { multiple: true, freeSolo: true, ...props, value: props.value || [], ref: ref, clearText: t('delete.all'), options: [], renderTags: (value, getTagProps, ownerState) => {
                return value?.map((option, index) => {
                    // Key value should be passed within `key` prop only. When it used through spread operator it leads to react console error.
                    const { key, ...tagProps } = getTagProps({ index });
                    return _jsx(Chip, { variant: "outlined", size: "small", color: "primary", label: option, ...tagProps }, key);
                });
            }, renderInput: (params) => (
            // Only Mui native TextFiled works inside Autocomplete.
            _jsx(MuiTextField, { ...params, inputRef: inputRef, size: "small", placeholder: t('multi.email.field.autocomplete.placeholder') })), onChange: (event, value) => {
                props?.onChange?.(value);
                stateCopy.current = value; // Mui bug. string[] is actually here instead of string[] | null.
            }, onInputChange: (event, value, reason) => {
                //console.log('onInputChange event: ', event);
            }, onKeyDown: (event) => {
                if (event.key !== 'Enter') {
                    // setTooltipOpen(false);
                    setTooltipTitle(null);
                }
                if (event.code === 'Space') {
                    event.preventDefault();
                    return;
                }
                if (event.key === 'Enter') {
                    const value = event.target.value.trim();
                    if (value === '') {
                        // @ts-ignore
                        event.defaultMuiPrevented = true;
                    }
                    // Prevent adding duplicates.
                    else if (stateCopy.current.includes(value)) {
                        // @ts-ignore
                        event.defaultMuiPrevented = true;
                        setTooltipTitle(t('the.entered.email.address.has.already.been.added.to.the.list'));
                    }
                    // Email test
                    else if (!russianDomainEmailRegex.test(value)) {
                        // @ts-ignore
                        event.defaultMuiPrevented = true;
                        setTooltipTitle(t('invalid.email.address'));
                    }
                }
                // Prevent email <Chip> from being deleted on backspace button press.
                else if (event.target.value === '' && event.key === 'Backspace') {
                    // @ts-ignore
                    event.defaultMuiPrevented = true;
                }
            } }) }));
});
export { MultiEmailField };
