import { jsx as _jsx } from "react/jsx-runtime";
import { MuiDialogStyled } from './Dialog.styled';
/**
 * **Global Parts** `Dialog` component.
 *
 * Disables close on *backdropClick* event.
 *
 * Additional properties: `fullHeight`.
 */
const Dialog = ({ onClose, ...props }) => {
    return (_jsx(MuiDialogStyled, { ...props, onClose: (event, reason) => {
            if (reason === 'escapeKeyDown')
                onClose?.(event, reason);
        } }));
};
export { Dialog };
